import React from "react"
import { Link } from "gatsby"

import Barre from "../components/barre"
import Footer from "../components/footer"
import Diapo from "../components/diapo"

import "../css/style.css"
import "../css/home.css"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import "aos/dist/aos.css"
import AOS from "aos/dist/aos.js"

class Home extends React.Component {
  componentDidMount() {
    AOS.init()
  }


  render() {
  return <div>
    <GatsbySeo
      language='fr'
      title='Mariée Création Passion / A Propos'
      description='Mariée Création Passion situé sur la Côte d Azur près de Cannes, crée et réalise vos modèles exclusifs sur mesures'
    />
    <Barre />

    <div id="APropos" class="Titre container">
      <div class="row">
        <div class="col-12 col-xl-8 d-flex d-xl-block text-center text-xl-start">
          <h2>A propos</h2>
          <p data-aos="fade-right" data-aos-duration="500">
            Depuis toujours, la couture est ma passion !<br /><br />

            A 10 ans déjà, j'apprenais le béaba de la couture et réalisais mes premiers vêtements...<br /><br />

            De toute évidence, j'ai suivi la voie des métiers de la mode à <b>Paris</b>, capitale de la haute-couture, pour préparer le <b>BTS <mark><i>« Métier de la Mode »</i></mark></b>, qui ma formée au métier de modéliste.<br />

            <b>Modéliste</b>, désormais spécialisée dans les <b>accessoires de mariée uniques et  sur-mesure</b>, je me suis formée au sein de bureaux d’étude de confection dans divers domaines : en <mark>« prêt-à-porter femme »</mark>, vêtements de danse et de compétition avant de s'orienter vers la <b>pièce unique et sur mesure</b> : robes de mariées, de soirée, pour enfin me spécialiser dans le costume historique et de scène pour les théâtres, les opéras notamment de Nice, le Capitole à Toulouse…
            <br /><br />
            C'est en poste de modéliste au bureau de style : <mark>« <b><i>Max Chaoul</i></b> »</mark>, créateur lyonnais de robes de mariée <b>haut-de-gamme</b> que je me suis passionnée pour le travail minutieux et créatif de la robe de <b>mariée</b>, qui a alors révélé mon désir de se spécialiser dans la <b>création d'accessoires et vêtements de mariage</b> dans un <b>concept innovant</b>  : 
          </p>
          <p data-aos="fade-top" class="text-center citation" data-aos-duration="500">
          <span>"Celui de pouvoir <b>créer</b> & <b>réaliser</b> sur mesure et à la demande du client, tous vêtements et <b>accessoires uniques</b> qui composent la tenue de la <b>mariée</b>, du <b>marié</b>, et son <b>cortège</b>, avec surtout la possibilité de <b>pouvoir les personnaliser</b> selon un thème et les <b>assortir les uns par rapport aux autres.</b>"<br />
            </span>
          </p>
        </div>
        <div class="col-10 col-xl-4 mx-auto">
          <div class="ImgPresentation">
            <div class="Image" id="imgApropos"></div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
}
}
export default Home